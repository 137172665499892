import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "BESDA";

export const client = prismic.createClient(repositoryName, {
    // If your repository is private, add an access token
    accessToken: "",

    // This defines how you will structure URL paths in your project.
    // Update the types to match the custom types in your project, and edit
    // the paths to match the routing in your project.
    //
    // If you are not using a router in your project, you can change this
    // to an empty array or remove the option entirely.
    routes: [
        // {
        //     type: "home_page",
        //     path: "/",
        // },
        // {
        //     type: "who_we_are",
        //     path: "/who-we-are",
        // },
        // {
        //     type: "resource",
        //     path: "/publications/resources",
        // },
        // {
        //     type: "blopgpost",
        //     resolvers: {
        //         category: "category",
        //     },
        //     path: "/publications/blog/:category/:uid",
        // },
        // {
        //     type: "category",
        //     path: "/publications/blog/:uid",
        // },
        // {
        //     type: "newsletter",
        //     resolvers: {
        //         category: "category",
        //     },
        //     path: "/publications/news-letters/:category/:uid",
        // },
        // {
        //     type: "newsletter_category",
        //     path: "/publications/news-letters/:uid",
        // },
        // {
        //     type: "report",
        //     resolvers: {
        //         category: "category",
        //     },
        //     path: "/publications/reports/:category/:uid",
        // },
        // {
        //     type: "report_category",
        //     path: "/publications/reports/:uid",
        // },
        // {
        //     type: "event",
        //     resolvers: {
        //         category: "category",
        //     },
        //     path: "/news-and-events/events/:category/:uid",
        // },
        // {
        //     type: "event_category",
        //     path: "/news-and-events/events/:uid",
        // },
        // {
        //     type: "press_release",
        //     resolvers: {
        //         category: "category",
        //     },
        //     path: "/news-and-events/press-release/:category/:uid",
        // },
        // {
        //     type: "press_release_category",
        //     path: "/news-and-events/press-release/:uid",
        // },
        // {
        //     type: "video_gallery",
        //     resolvers: {
        //         category: "category",
        //     },
        //     path: "/media-room/video-gallery/:category/:uid",
        // },
    ],
});
