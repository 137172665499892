import React from "react";
import { Route, Routes as Switch } from "react-router";
import BlogPage from "./blog";
import BlogCategoryPage from "./blog/category";
import BlogDetailsPage from "./blog/category/details";

import NewsletterPage from "./news-letter";
import NewsletterCategoryPage from "./news-letter/category";
import NewsletterDetailsPage from "./news-letter/category/details";

import ReportsPage from "./reports";
import ReportsCategoryPage from "./reports/category";
import ReportsDetailsPage from "./reports/category/details";

import ResourcesPage from "./resources";
import ResourcesCategoryPage from "./resources/category";
import PageNotFound from "../404";

const PublicationRoutes = () => {
    return (
        <Switch>
            <Route path='/blog'>
                <Route index element={<BlogPage />} />
                <Route path=':category/*'>
                    <Route index element={<BlogCategoryPage />} />
                    <Route path=':slug' element={<BlogDetailsPage />} />
                </Route>
            </Route>
            <Route path='news-letters'>
                <Route index element={<NewsletterPage />} />
                <Route path=':category/*'>
                    <Route index element={<NewsletterCategoryPage />} />
                    <Route path=':slug' element={<NewsletterDetailsPage />} />
                </Route>
            </Route>
            <Route path='reports'>
                <Route index element={<ReportsPage />} />
                <Route path=':category/*'>
                    <Route index element={<ReportsCategoryPage />} />
                    <Route path=':slug' element={<ReportsDetailsPage />} />
                </Route>
            </Route>
            <Route path='resources'>
                <Route index element={<ResourcesPage />} />
                <Route path=':category/*'>
                    <Route index element={<ResourcesCategoryPage />} />
                </Route>
            </Route>
            <Route path='*' element={<PageNotFound />} />
        </Switch>
    );
};

export default PublicationRoutes;
