import {
    usePrismicDocumentsByType,
    usePrismicDocumentByUID,
} from "@prismicio/react";
import * as prismic from "@prismicio/client";
import React from "react";
import { useParams } from "react-router-dom";
import BlogDetails from "../../../../../components/BlogDetails";

const PressReleaseDetailsPage = () => {
    const { slug } = useParams();

    const [news, { state }] = usePrismicDocumentByUID("news", slug, {
        fetchLinks: ["category.title"],
    });
    const [relatedNews] = usePrismicDocumentsByType("news", {
        filters: [prismic.filter.not("my.news.uid", slug)],
        fetchLinks: ["category.title"],
        graphQuery: `{
            news{
                title
                feature_image
                short_description
            }
        }`,
        pageSize: 4,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
    });

    const [categories] = usePrismicDocumentsByType("category");

    return (
        <BlogDetails
            post={news}
            categories={categories?.results}
            link={"/news-and-events/press-release/"}
            heading={"News and Press Release"}
            relatedPosts={relatedNews?.results}
            publicationType={"news"}
            state={state}
        />
    );
};

export default PressReleaseDetailsPage;
