import { usePrismicDocumentsByType } from "@prismicio/react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import VideoGallery from "../../../components/VideoGallery";

const VideoGalleryPage = () => {
    const [searchParams] = useSearchParams();

    const [videos, { state }] = usePrismicDocumentsByType("video_galle", {
        fetchLinks: ["category.title"],
        graphQuery: `{
            video_galle{
                thumbnail
                category
            }
        }

        `,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 9,
        page: searchParams.get("page") || 1,
    });
    const [categories] = usePrismicDocumentsByType("media_room_category");

    return (
        <VideoGallery
            tabs_category={categories?.results}
            videos={videos?.results}
            totalPages={videos?.total_pages}
            state={state}
        />
    );
};

export default VideoGalleryPage;
