import React, { Suspense, useEffect } from "react";
import { Route, Routes as Switch } from "react-router";
import AOS from "aos";
import "aos/dist/aos.css";
import Homepage from "../pages/homepage";
import WhatWeDo from "../pages/what-we-do";
import WhoWeAre from "../pages/who-we-are";
import MediaRoomRoutes from "../pages/media-room";
import NewsAndEventRoutes from "../pages/news-and-events";
import PublicationRoutes from "../pages/publications";
import SearchPage from "../pages/search";
import PageNotFound from "../pages/404";

const Routes = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false,
        });
    }, []);
    return (
        <Switch>
            <Route path='/' element={<Homepage />}></Route>
            <Route path='/what-we-do' element={<WhatWeDo />} />
            <Route path='/who-we-are' element={<WhoWeAre />} />
            <Route path='/publications/*' element={<PublicationRoutes />} />
            <Route path='/media-room/*' element={<MediaRoomRoutes />} />
            <Route path='/news-and-events/*' element={<NewsAndEventRoutes />} />
            <Route path='/search' element={<SearchPage />} />
            <Route path='*' element={<PageNotFound />} />
        </Switch>
    );
};

export default Routes;
