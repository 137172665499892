import React from "react";
import BlogRight from "./BlogRight";
import EachBlog from "./EachBlog";

import EventCard from "./EventCard";
import EachResource from "./EachResource";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Pagination from "./Pagination";

export default function Blog({
    heading,
    posts,
    totalPages,
    categories,
    link,
    checkEvent,
    isResource,
    state,
}) {
    const [router] = useSearchParams();

    const currentPage = Number(router.get("page")) || 1;
    const location = useLocation();
    const { pathname } = location;

    // const pathname = pathname.split("/page")[0]; // doing this to get the accurate pathname
    // so that the component is reuseable for  both the blog page and the category pages

    const pageNumbers = Array.from(
        { length: totalPages },
        (_, index) => index + 1
    );

    return (
        <div>
            <div
                className='bg-1 bg-theme-'
                style={{ height: "112px", backgroundColor: "#12B76A" }}
            >
                <div className='container d-flex flex-column justify-content-center h-100'>
                    <h1
                        style={{ textTransform: "capitalize" }}
                        className='fw-400 text-white'
                    >
                        {heading}
                    </h1>
                </div>
            </div>
            <section className='blog-post-area ptb-100'>
                <div className='container'>
                    <div className='row'>
                        {state === "loading" ? (
                            <div
                                style={{
                                    minHeight: "100vh",
                                    display: "grid",
                                    placeItems: "center",
                                }}
                            >
                                <ThreeDots
                                    visible={true}
                                    height='80'
                                    width='80'
                                    color='#4fa94d'
                                    radius='9'
                                    ariaLabel='three-dots-loading'
                                    wrapperStyle={{}}
                                    wrapperClass=''
                                />
                            </div>
                        ) : posts?.length > 0 ? (
                            <div className='col-lg-8'>
                                <div className='row'>
                                    {posts?.map((post) =>
                                        checkEvent ? (
                                            <EventCard
                                                key={post.id}
                                                date={post?.data?.date}
                                                title={
                                                    post?.data?.title[0]?.text
                                                }
                                                category={
                                                    post?.data?.category?.data
                                                        ?.title[0]?.text
                                                }
                                                img={
                                                    post?.data?.feature_image
                                                        ?.url
                                                }
                                                alt={
                                                    post?.data?.feature_image
                                                        ?.alt
                                                }
                                                image={
                                                    post?.data?.feature_image
                                                        ?.url
                                                }
                                                location={post?.data?.location}
                                                link={`${link}${post?.data?.category?.uid}/${post.uid}`}
                                            />
                                        ) : isResource ? (
                                            <EachResource
                                                isProtected={
                                                    post?.data?.protected
                                                }
                                                title={
                                                    post?.data?.title[0]?.text
                                                }
                                                alt={
                                                    post?.data?.featured_image
                                                        ?.alt
                                                }
                                                image={
                                                    "/assets/figma/filePlaceholder.jpeg"
                                                }
                                                content={post?.data?.content}
                                                downloadLink={
                                                    post?.data?.resource?.url
                                                }
                                                documentName={
                                                    post?.data?.resource?.name
                                                }
                                                password={post?.data?.password}
                                            />
                                        ) : (
                                            <EachBlog
                                                category={
                                                    post?.data?.category?.data
                                                        ?.title[0]?.text
                                                }
                                                title={
                                                    post?.data?.title[0]?.text
                                                }
                                                img={
                                                    post?.data?.feature_image
                                                        ?.url
                                                }
                                                created_at={
                                                    post?.first_publication_date
                                                }
                                                alt={
                                                    post?.data?.feature_image
                                                        ?.alt
                                                }
                                                description={
                                                    post?.data?.description
                                                }
                                                categoryLink={`${link}${post?.data?.category?.uid}`}
                                                link={`${link}${post?.data?.category?.uid}/${post.uid}`}
                                            />
                                        )
                                    )}
                                </div>
                                <br />
                                <br />
                                <Pagination total_pages={totalPages} />
                            </div>
                        ) : (
                            <div
                                className='col-lg-8'
                                style={{
                                    height: "30vh",
                                    display: "grid",
                                    placeItems: "center",
                                    fontWeight: "600",
                                }}
                            >
                                <p>No posts found</p>
                            </div>
                        )}
                        <br />
                        <br />
                        <div className='col-lg-4'>
                            <BlogRight categories={categories} link={link} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
