import React from "react";

const Pillars = () => {
    return (
        <>
            <PillarOne />
            <PillarTwo />
            <PillarThree />
            {/* <PillarFour /> */}
            {/* <PillarFive />
          <PillarSix /> */}
        </>
    );
};

export default Pillars;

function PillarOne() {
    return (
        <section className='local-councils-services-area pb-70 pt-70'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <div className='local-councils-content pr-15'>
                            <h2 style={{ fontSize: "30px" }}>
                                Pillar 1: Reduction in the Number of
                                Out-of-School Children
                            </h2>
                            <p style={{ fontSize: "20px" }}>
                                Our focus on equity drives this results area,
                                aiming to ensure more equitable allocation of
                                state education resources. Specifically, we work
                                towards improving learning conditions in the
                                most disadvantaged schools and providing
                                opportunities for students to transition to
                                junior secondary education in communities
                                without junior secondary schools. Additionally,
                                our program incentivizes the development of a
                                mechanism in each state to ensure the equitable
                                distribution of resources beyond our
                                intervention.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6' bis_skin_checked='1'>
                        <div
                            className='local-councils-img pl-15'
                            bis_skin_checked='1'
                        >
                            <img
                                src='/assets/images/what-we-do/tess_school_2.jpeg'
                                alt='Image'
                                className='rounded'
                            />
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Indicator:
                    </p>
                    <p style={{ fontSize: "20px" }}>
                        State infrastructure budget allocated to underserved
                        communities to improve learning conditions in primary
                        schools and improve access to junior secondary schools.{" "}
                        <br /> This DLI consists of two key results:
                    </p>

                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 1: Development of school
                        network
                    </p>
                    <p style={{ fontSize: "20px" }}>
                        States to develop a school network encompassing all
                        primary and junior secondary schools, utilizing
                        geo-coded school infrastructure audit data and
                        population estimates for school-age children.
                    </p>
                    <br />
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 2: Underserved communities
                        receiving grants for infrastructure development
                    </p>
                    <ul>
                        <li>
                            We aim to improve access to education for
                            underserved communities by providing grants to
                            School-Based Management Committees (SBMCs) through
                            the State Universal Basic Education Board (SUBEB).
                            The grants will be used to extend existing primary
                            schools into junior secondary schools, add new
                            classroom blocks, renovate primary school buildings,
                            and provide separate WASH facilities for girls. We
                            will measure success by the number of male and
                            female students gaining access to schools with
                            improved learning conditions. This initiative
                            includes conducting infrastructure audits,
                            prioritizing schools with the highest deficits, and
                            allocating resources accordingly. By addressing
                            infrastructure needs, we promote equitable access to
                            basic education and create better learning
                            environments for students.
                        </li>
                    </ul>
                    <p style={{ fontSize: "20px" }}></p>
                </div>
            </div>
        </section>
    );
}

function PillarThree() {
    return (
        <section className='local-councils-services-area pb-70 pt-70'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <div className='local-councils-content pr-15'>
                            <h2 style={{ fontSize: "30px" }}>
                                Pillar  3: Improving Teacher Development and
                                Making Education Expenditures Transparent and
                                accessible For Policy Formulation
                            </h2>
                            <p style={{ fontSize: "20px" }}>
                                The objective of this expanded results area is
                                to reduce the overall unit cost of education
                                provision through improved teacher deployment
                                practices and improved transparency of education
                                spending data. This will be achieved through the
                                following Disbursement Linked Indicators and
                                Disbursement Linked Results:
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6' bis_skin_checked='1'>
                        <div
                            className='local-councils-img pl-15'
                            bis_skin_checked='1'
                        >
                            <img
                                src='/assets/images/what-we-do/tess_school.jpeg'
                                alt='Image'
                                className='rounded'
                            />
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Indicator:
                    </p>
                    <ul>
                        <li>Improved Teacher Deployment</li>
                    </ul>
                </div>
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 1: Teacher Deployment Policy:
                    </p>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            States are required to develop and issue a
                            State-level Teacher Deployment Policy that is
                            aligned with overall basic education policies,
                            stipulate merit-based recruitment policies and
                            need-based teacher deployment policies, developed
                            with adequate stakeholder engagement that shows
                            transparency and prioritizes staffing rural primary
                            schools with high PTRs with shortage of teachers,
                            and prescribes location-based incentives for
                            teachers deployed to hard-to-serve locations and
                            rural areas with high PTRs.
                        </li>
                    </ul>
                </div>

                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 2: Improved Teacher
                        Deployment:
                    </p>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            States will be rewarded US$2,000 for each newly
                            recruited teacher deployed to rural schools which
                            have been identified as having a shortage of
                            teachers specifically to those rural schools with a
                            PTR greater than 70. Similarly, States will also be
                            rewarded for redeployment of teachers from primary
                            schools with a PTR below 30 to schools with a PTR
                            above 70.
                        </li>
                    </ul>
                </div>

                {/* SECOND */}
                <br />
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Indicator:
                    </p>
                    <ul>
                        <li style={{ fontSize: "20px" }}>
                            Improved Transparency in Education Spending <br />
                            This DLI has two key results.{" "}
                        </li>
                    </ul>
                </div>
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 1: State Education
                        Expenditures Accounts published:
                    </p>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            States are required to publish annual education
                            expenditure reports compiled and approved by the
                            State Accountant General. The report includes
                            spending by administrative units in the education
                            and training sector, including other Ministries,
                            Departments and Agencies (MDAs); by economic
                            classification such as capital and recurrent
                            expenditures; by functions such as levels of
                            education including pre-school, primary, JSS, Senior
                            Secondary School (SSS), non-formal, post-secondary
                            non-tertiary and tertiary.
                        </li>
                    </ul>
                </div>

                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 2: SUBEB Expenditure Accounts
                        by Program and Outputs published:
                    </p>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            States are required to publish SUBEB’s expenditures
                            and outputs by program such as matching grants for
                            infrastructure; Teacher Professional Development;
                            Instructional and learning materials; Special needs
                            education; Imbalance and good governance programs.
                            The report should include the list of schools with
                            their geo-coordinates, that benefit from SUBEBs’
                            investment. For each beneficiary school, the report
                            should include information on infrastructure built
                            or upgraded, training programs for teachers and
                            number of teachers participating, number of
                            instructional materials provided and number of
                            students benefited.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

function PillarTwo() {
    return (
        <section className='local-councils-services-area pb-70 pt-70'>
            <div className='container'>
                <div
                    style={
                        {
                            // flexDirection: 'row-reverse',
                        }
                    }
                    className='row align-items-center '
                >
                    <div className='col-lg-6' bis_skin_checked='1'>
                        <div
                            className='local-councils-img pl-15'
                            bis_skin_checked='1'
                        >
                            <img
                                src='/assets/images/what-we-do/tess_4.jpeg'
                                alt='Image'
                                className='rounded'
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='local-councils-content pr-15'>
                            <h2 style={{ fontSize: "30px" }}>
                                Pillar 2: Enhancing Teaching Practices and
                                Measuring Learning Outcomes
                            </h2>
                            <p style={{ fontSize: "20px" }}>
                                At TESS, we are committed to improving the
                                quality of education by enhancing teaching
                                practices and measuring learning outcomes. Our
                                focus is to strengthen teachers' capacity in OAK
                                states through a structured pedagogy program
                                based on digital technology. By achieving this,
                                we aim to foster effective teaching methods and
                                provide valuable insights for policy
                                formulation.
                            </p>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Indicator:
                    </p>
                    <ul>
                        <li>
                            Structured Pedagogy Program for Effective Teaching.{" "}
                            <br /> Under this program, we have developed two key
                            deliverables:
                        </li>
                    </ul>
                </div>
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 1:Empowering Teachers with
                        Relevant Training:
                    </p>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            We provide teachers with comprehensive training
                            modules tailored to their specific teaching needs.
                            These modules, available in both digital and print
                            formats, cover essential subjects and pedagogical
                            skills. Through short practical training sessions,
                            teachers gain in-depth knowledge of the curriculum
                            and effective teaching strategies. Additionally, our
                            cluster-based coaching sessions reinforce and
                            enhance teachers' training, curriculum knowledge,
                            and pedagogical skills.
                        </li>
                    </ul>
                </div>
                <br />
                <div style={{ fontSize: "20px" }}>
                    <p style={{ fontSize: "20px" }} className='fw-bold'>
                        Disbursement-Linked Result 2: Assessing Student Learning
                        Outcomes
                    </p>
                    <ol style={{ listStyleType: "lower-roman" }}>
                        <li>
                            To ensure continuous improvement, teachers conduct
                            termly assessments for at least 80% of their
                            students annually. These assessments, aligned with
                            the curriculum, enable teachers to gauge students'
                            progress and adjust their teaching accordingly. Our
                            digital platform facilitates the grading and
                            analysis of assessment results, providing valuable
                            insights for teachers and School Pedagogical Support
                            Officers (SPSOs) to enhance classroom instruction.
                            By implementing this program, we aim to achieve the
                            following outcomes and impacts: Increased number of
                            students benefiting from effective teaching
                            practices.
                        </li>
                        <li>
                            Regular student assessments that inform teaching
                            adjustments and improvement.
                        </li>
                        <li>
                            Individualized reports provided to teachers by
                            SPSOs, based on coaching interactions and assessment
                            results, guiding them to enhance their teaching
                            practice.
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    );
}
