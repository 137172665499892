import React from "react";
import WhoWeAreHeroSection from "./Herosection";
import InstitutionalAgreementWhoWeAre from "./InstitutionalAgreement";

const WhoWeAre = () => {
    return (
        <>
            <WhoWeAreHeroSection />
            <InstitutionalAgreementWhoWeAre />
        </>
    );
};

export default WhoWeAre;
