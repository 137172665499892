import React, { useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
const Pagination = ({ total_pages }) => {
    const [router] = useSearchParams();
    const location = useLocation();
    const { pathname } = location;

    const currentPage = Number(router.get("page")) || 1;
    const [visiblePages, setVisiblePages] = useState(5);

    return (
        <div
            style={{ marginBottom: "2rem" }}
            className='col-12'
            bis_skin_checked='1'
        >
            <div className='pagination-area' bis_skin_checked='1'>
                <NavLink
                    style={{
                        display:
                            !currentPage || currentPage === 1
                                ? "none"
                                : "inline-block",
                    }}
                    to={`${pathname}?page=${currentPage - 1}`}
                    className='next page-numbers'
                >
                    <i className='ri-arrow-left-line'></i>
                </NavLink>
                <RenderPageNumbers
                    route={pathname}
                    visiblePages={visiblePages}
                    total={total_pages}
                    currentPage={currentPage}
                />{" "}
                <NavLink
                    style={{
                        display:
                            !currentPage ||
                            currentPage === total_pages ||
                            total_pages == 1
                                ? "none"
                                : "inline-block",
                    }}
                    to={`${pathname}?page=${currentPage + 1}`}
                    className='next page-numbers'
                >
                    <i className='ri-arrow-right-line'></i>
                </NavLink>
            </div>
        </div>
    );
};

const RenderPageNumbers = ({ visiblePages, total, currentPage, route }) => {
    const pages = [];
    let startPage = 1;
    if (currentPage > Math.floor(visiblePages / 2)) {
        startPage = currentPage - Math.floor(visiblePages / 2);
    }

    const endPage = Math.min(total, startPage + visiblePages - 1);

    if (startPage > 1) {
        pages.push(
            <NavLink
                to={`${route}?page=1`}
                className={`page-numbers ${currentPage === 1 && "current"}`}
            >
                1
            </NavLink>
        );
    }

    if (startPage > 2) {
        pages.push(
            <span style={{ display: "inline-block" }} className='page-numbers'>
                ...
            </span>
        );
    }

    for (let i = startPage; i <= endPage; i++) {
        pages.push(
            <NavLink
                to={`${route}?page=${i}`}
                className={`page-numbers ${i === currentPage && "current"}`}
            >
                {i}
            </NavLink>
        );
    }

    if (endPage < total) {
        if (endPage < total - 1) {
            pages.push(
                <span
                    style={{ display: "inline-block" }}
                    className='page-numbers'
                >
                    ...
                </span>
            );
        }
        pages.push(
            <NavLink
                to={`${route}?page=${total}`}
                className={`page-numbers ${total === currentPage && "current"}`}
            >
                {total}
            </NavLink>
        );
    }

    return pages;
};

export default Pagination;
