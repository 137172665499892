import React from "react";
import EachVideo from "./EachVideo";
import { Tabs } from "./PhotoGallery";
import Pagination from "./Pagination";
import { ThreeDots } from "react-loader-spinner";

export default function VideoGallery({
    tabs_category,
    videos,
    totalPages,
    state,
}) {
    return (
        <div>
            <div
                className='bg-1 bg-theme-'
                style={{ height: "112px", backgroundColor: "#12B76A" }}
            >
                <div className='container d-flex flex-column justify-content-center h-100'>
                    <h1 className='fw-400 text-white'>Video Gallery</h1>
                </div>
            </div>
            {state === "loading" ? (
                <div
                    style={{
                        minHeight: "100vh",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <ThreeDots
                        visible={true}
                        height='80'
                        width='80'
                        color='#4fa94d'
                        radius='9'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClass=''
                    />
                </div>
            ) : (
                <section className='project-area pt-100 pb-70'>
                    <div className='container'>
                        <Tabs
                            tabs_category={tabs_category}
                            link={"/media-room/video-gallery"}
                        />
                        <div className='row'>
                            {videos?.map((video, index) => (
                                <EachVideo
                                    key={`video_${index}`}
                                    thumbnail={video?.data?.thumbnail?.url}
                                    link={`/media-room/video-gallery/${video.data?.category?.uid}/${video?.uid}`}
                                    title={video?.data?.title}
                                    alt={video?.data?.thumbnail?.alt || "image"}
                                />
                            ))}
                        </div>
                    </div>
                    <br />
                    <br />
                    <Pagination total_pages={totalPages} />
                </section>
            )}
        </div>
    );
}
