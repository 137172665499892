import moment from "moment";

import React from "react";
import { NavLink } from "react-router-dom";

const EachSearchBlog = ({ posts, queryWord, link }) => {
    return (
        <>
            {posts?.map((post) => {
                const regex = new RegExp(queryWord, "gi");
                const parts =
                    post?.data?.title &&
                    post?.data?.title[0]?.text?.split(regex);

                return (
                    <div style={{ marginTop: "24px" }}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <NavLink
                                to={`${link}/${post?.data?.category?.uid}/${post?.uid}`}
                                style={{
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    color: "#101828",
                                    textTransform: "capitalize",
                                }}
                            >
                                {parts?.length > 0 ? parts[0] : ""}{" "}
                                <span
                                    style={{
                                        textTransform: "capitalize",
                                        background: "#FEC84B",
                                    }}
                                >
                                    {post?.data?.title &&
                                        regex.test(
                                            post?.data?.title[0]?.text
                                        ) &&
                                        queryWord}
                                </span>{" "}
                                {parts?.length > 0 ? parts[1] : ""}
                            </NavLink>
                            <span>
                                Posted{" "}
                                {moment(post?.first_publication_date).fromNow()}
                            </span>
                        </div>
                        <p style={{ fontSize: "16px" }}>
                            {post.data?.short_description}
                        </p>
                    </div>
                );
            })}
        </>
    );
};

export default EachSearchBlog;
