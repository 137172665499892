import React from "react";
import Pillars from "./Pillars";

const WhatWeDo = () => {
    return (
        <>
            <div
                className='bg-1 bg-theme- '
                style={{ height: "112px", backgroundColor: "#12B76A" }}
            >
                <div className='container d-flex flex-column justify-content-center h-100'>
                    <h1 className='fw-400 text-white'>What We Do</h1>
                </div>
            </div>

            <Pillars />
        </>
    );
};

export default WhatWeDo;
