import React from "react";

const patnersDetails = [
    {
        url: "/assets/images/patners/gpe.webp",
        alt: "gpe",
    },
    {
        url: "/assets/images/patners/coat-of-arm.webp",
        alt: "nigerian coat of arms",
    },
    {
        url: "/assets/images/patners/world-bank.webp",
        alt: "world bank",
    },
];
const Partners = () => {
    return (
        <section
            className='container services-area-style-two '
            style={{ background: "white", padding: "34px" }}
        >
            <div
                style={{
                    flexWrap: "wrap",
                    gap: "1.5rem",
                }}
                className='d-flex justify-content-evenly'
            >
                {patnersDetails?.map((partner, i) => (
                    <img
                        key={`partner_${i}`}
                        src={partner.url}
                        alt={partner.alt || "partner logo"}
                        width={204.45}
                        height={48}
                        style={{ objectFit: "contain" }}
                    />
                ))}
            </div>
        </section>
    );
};

export default Partners;
