import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as prismic from "@prismicio/client";
import PhotoGallery from "../../../../components/PhotoGallery";

const PhotoGalleryCategory = () => {
    const { category } = useParams();
    const [searchParams] = useSearchParams();

    const [currentCategory] = usePrismicDocumentByUID(
        "media_room_category",
        category
    );

    const [photos, { state }] = usePrismicDocumentsByType("photo_gallery", {
        fetchLinks: ["category.title"],
        filters: [
            prismic.filter.at("my.photo_gallery.category", currentCategory?.id),
        ],
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 9,
        page: searchParams.get("page") || 1,
    });
    const [categories] = usePrismicDocumentsByType("media_room_category");

    return (
        <PhotoGallery
            tabs_category={categories?.results}
            photos={photos?.results}
            totalPages={photos?.total_pages}
            state={state}
        />
    );
};

export default PhotoGalleryCategory;
