import ScrollToTop from "./components/ScrollToTop";
import Layout from "./layout";
import Routes from "./routes";
import "./assets/css/remixicon.css";
import "./assets/css/flaticon.css";

function App() {
    return (
        <Layout>
            <ScrollToTop />
            <Routes />
        </Layout>
    );
}

export default App;
