import React from "react";
import { NavLink } from "react-router-dom";

const VideoResult = ({ posts, query }) => {
    return (
        <div
            className='row '
            style={{ marginTop: 20, alignItems: "stretch", gap: "32px" }}
        >
            {posts?.map((post) => {
                /*
				Perform a regex to check the query text is in the post title.
				If its there, split the post title by the query text
			*/
                const regex = new RegExp(query, "gi");
                const parts = post?.data?.title[0]?.text?.split(regex);
                return (
                    <div className='col-lg-5 col-md-6'>
                        <NavLink
                            to={`/media-room/video-gallery/${post?.data?.category?.uid}/${post?.uid}`}
                            style={{
                                display: "block",
                                position: "relative",
                                height: "400px",
                                overflow: "hidden",
                                borderRadius: "8px",
                                background:
                                    "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(.jpg)",
                            }}
                        >
                            <img
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                }}
                                src={
                                    post?.type === "photo_gallery"
                                        ? post?.data?.image?.url
                                        : post?.data?.thumbnail?.url
                                }
                                alt={
                                    post?.data?.thumbnail_image?.alt ||
                                    "photo gallery"
                                }
                            />
                        </NavLink>

                        <NavLink
                            to={`/media-room/video-gallery/${post?.data?.category?.uid}/${post?.uid}`}
                            style={{
                                fontSize: "20px",
                                display: "block",
                                fontWeight: "600",
                                color: "#101828",
                                marginTop: "24px",
                            }}
                        >
                            {parts?.length > 0 ? parts[0] : ""}{" "}
                            <span
                                style={{
                                    textTransform: "capitalize",
                                    background: "#FEC84B",
                                }}
                            >
                                {regex.test(post?.data?.title[0]?.text) &&
                                    query}
                            </span>{" "}
                            {parts?.length > 0 ? parts[1] : ""}
                        </NavLink>
                        <p style={{ fontSize: "16px" }}>
                            {post?.data?.short_description}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default VideoResult;
