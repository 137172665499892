import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation, useSearchParams } from "react-router-dom";
import Chatbot from "./Chatbot";
import Search from "./Search";

const Layout = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;
    const [router] = useSearchParams();

    const [openSearchComponent, setOpenSearchComponent] = useState(
        pathname === "/search" ? true : false
    );
    const [searchQuery, setSearchQuery] = useState(router.get("q") || "");

    return (
        <>
            <Header
                searchQuery={searchQuery}
                openSearchComponent={openSearchComponent}
                setOpenSearchComponent={setOpenSearchComponent}
                setSearchQuery={setSearchQuery}
            />
            <Search
                openSearchComponent={openSearchComponent}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />
            <main className='animate__fadeIn animate__animated '>
                {children}
            </main>
            <Chatbot />
            <Footer />
        </>
    );
};

export default Layout;
