import React from "react";

const WhoWeAre = () => {
    return (
        <section className='py-5 bg-theme-light'>
            <div className='container'>
                <h5
                    style={{ marginBottom: "25px", fontWeight: 500 }}
                    className='text-theme font-30'
                    data-aos='fade-up'
                >
                    Who we are
                </h5>
                <div className='text-justify'>
                    Better Education Service Delivery for All Additional
                    Finance; Transforming Education Systems at States Level
                    (BESDA AF-TESS) program was initiated in response to the
                    need for educational reform and improvements to support the
                    achievement of UBE programs in Nigeria. The program aims to
                    Increase equitable access for out-of-school children,
                    improve literacy rate and learning outcomes and strengthen
                    accountability for results by improving learning conditions
                    with focus on essential infrastructure such as classrooms
                    and WASH facilities for the most disadvantaged primary
                    schools, provision of junior secondary schools in
                    underserved communities to strengthen transition from
                    primary schools to junior secondary level, enhance teacher
                    quality, learning assessments, strengthening accountability
                    and promoting community engagement.
                </div>
            </div>
        </section>
    );
};

export default WhoWeAre;
