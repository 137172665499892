import moment from "moment";
import React from "react";

import ShareIcons from "./ShareIcons";
import { NavLink } from "react-router-dom";

import BlogRight from "./BlogRight";
import { PrismicRichText } from "@prismicio/react";
import { ThreeDots } from "react-loader-spinner";

export default function BlogDetails({
    post,
    categories,
    link,
    checkEvent,
    heading,
    relatedPosts,
    publicationType,
    state,
}) {
    console.log(relatedPosts);
    // const convertRichTextToPlain = RichText.asText(post?.description)
    return (
        <div>
            <div
                className='bg-1 bg-theme-'
                style={{ height: "112px", backgroundColor: "#12B76A" }}
            >
                <div className='container d-flex flex-column justify-content-center h-100'>
                    <h1 className='fw-400 text-white'>{heading}</h1>
                </div>
            </div>
            {state === "loading" ? (
                <div
                    style={{
                        minHeight: "100vh",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <ThreeDots
                        visible={true}
                        height='80'
                        width='80'
                        color='#4fa94d'
                        radius='9'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClass=''
                    />
                </div>
            ) : (
                <section className='blog-details-area ptb-100'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='blog-details-content'>
                                    <div
                                        className='blog-details-img'
                                        bis_skin_checked='1'
                                    >
                                        <img
                                            // fill
                                            // style={{ objectFit: 'contain' }}
                                            style={{
                                                width: "100%",
                                            }}
                                            src={post?.data?.feature_image?.url}
                                            alt={post?.data?.feature_image?.alt}
                                        />
                                    </div>
                                    <div className='blog-top-content pb-100'>
                                        <div className='news-content'>
                                            <ul className='admin d-flex'>
                                                <li>
                                                    <NavLink
                                                        to={`${link}/${post?.category?.slug}`}
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                        className='d-flex align-items-center'
                                                    >
                                                        {
                                                            post?.data?.category
                                                                ?.data?.title[0]
                                                                ?.text
                                                        }
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <i className='ri-calendar-line'></i>
                                                    {moment(
                                                        post?.first_publication_date
                                                    ).format("DD MMMM")}
                                                </li>
                                            </ul>

                                            <h3 className='text-muted'>
                                                {post?.data?.title[0]?.text}
                                            </h3>

                                            {
                                                <PrismicRichText
                                                    field={post?.data?.content}
                                                />
                                            }

                                            {checkEvent &&
                                                post?.data?.video?.url && (
                                                    <div>
                                                        <video
                                                            src={
                                                                post?.data
                                                                    ?.video?.url
                                                            }
                                                            controls
                                                            className='w-100 h-100 video_poster'
                                                        ></video>
                                                    </div>
                                                )}

                                            <ShareIcons
                                                title={
                                                    post?.data?.title[0]?.text
                                                }
                                                url={`${link}${post?.data?.category?.uid}/${post?.uid}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className='col-lg-4'>
                                <BlogRight
                                    categories={categories}
                                    link={link}
                                />
                                <RelatedPosts
                                    heading={heading}
                                    posts={relatedPosts}
                                    link={link}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
}

const RelatedPosts = ({ posts, heading, link }) => {
    return (
        <div style={{ width: "100%", marginTop: "42px" }} bis_skin_checked='1'>
            <div className='widget-sidebar pl-15' bis_skin_checked='1'>
                <div className='sidebar-widget categories' bis_skin_checked='1'>
                    <h3>Related {heading} </h3>

                    <>
                        {posts?.map((post) => (
                            <div key={post.uid} bis_skin_checked='1'>
                                <div
                                    style={{
                                        marginTop: "12px",
                                        position: "relative",
                                        height: "200px",
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                        background:
                                            "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
                                    }}
                                >
                                    <img
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                        src={post?.data?.feature_image?.url}
                                        alt={
                                            post?.data?.feature_image?.alt ||
                                            "blog image"
                                        }
                                    />

                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "16px",
                                            margin: "auto",
                                            left: "0",
                                            right: "0",
                                            textAlign: "center",
                                            color: "white",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            backdropFilter: "blur(10px)",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            width: "90%",
                                            padding: "8px",
                                        }}
                                        className='project-content'
                                        bis_skin_checked='1'
                                    >
                                        <NavLink
                                            style={{
                                                color: "white",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                                                padding: "1rem",
                                            }}
                                            to={`${link}${post?.data?.category?.uid}/${post?.uid}`}
                                        >
                                            {post?.data?.title[0]?.text}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                </div>
            </div>
        </div>
    );
};
