import React from "react";
import Blog from "../../../../components/Blog";
import * as prismic from "@prismicio/client";
import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const EventCategoryPage = () => {
    const { category } = useParams();
    const [searchParams] = useSearchParams();

    const [currentCategory] = usePrismicDocumentByUID("category", category);
    const [categories] = usePrismicDocumentsByType("category");
    const [events, { state }] = usePrismicDocumentsByType("event", {
        filters: [prismic.filter.at("my.event.category", currentCategory?.id)],
        fetchLinks: ["category.title"],
        graphQuery: `{
            event{
                title
                feature_image
                short_description
                category
                location
            }
        }`,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
        pageSize: 10,
        page: searchParams.get("page") || 1,
    });

    return (
        <Blog
            heading={"Events"}
            posts={events?.results}
            checkEvent={true}
            totalPages={events?.total_pages}
            categories={categories?.results}
            link={"/news-and-events/events/"}
            state={state}
        />
    );
};

export default EventCategoryPage;
