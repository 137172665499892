import {
    usePrismicDocumentByUID,
    usePrismicDocumentsByType,
} from "@prismicio/react";
import React from "react";
import { useParams } from "react-router-dom";
import BlogDetails from "../../../../../components/BlogDetails";
import * as prismic from "@prismicio/client";

const NewsletterDetailsPage = () => {
    const { slug } = useParams();

    const [newsletter, { state }] = usePrismicDocumentByUID(
        "newsletter",
        slug,
        {
            fetchLinks: ["category.title"],
        }
    );
    const [relatedNewsletter] = usePrismicDocumentsByType("newsletter", {
        filters: [prismic.filter.not("my.newsletter.uid", slug)],
        fetchLinks: ["category.title"],
        graphQuery: `{
            newsletter{
                title
                feature_image
                short_description
                category
            }
        }`,
        pageSize: 4,
        orderings: [
            {
                field: "first_publication_date",
                direction: "desc",
            },
        ],
    });

    const [categories] = usePrismicDocumentsByType("category");

    return (
        <BlogDetails
            post={newsletter}
            categories={categories?.results}
            link={"/publications/news-letters/"}
            heading={"Newsletter"}
            relatedPosts={relatedNewsletter?.results}
            publicationType={"newsletter"}
            state={state}
        />
    );
};

export default NewsletterDetailsPage;
