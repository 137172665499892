import React from "react";
import { Route, Routes as Switch } from "react-router";
import PressReleasePage from "./press-release";
import PressReleaseCategoryPage from "./press-release/category";
import PressReleaseDetailsPage from "./press-release/category/details";
import EventPage from "./events";
import EventCategoryPage from "./events/category";
import EventDetailsPage from "./events/category/details";
import PageNotFound from "../404";

const NewsAndEventRoutes = () => {
    return (
        <Switch>
            <Route path='/press-release'>
                <Route index element={<PressReleasePage />} />
                <Route path=':category/*'>
                    <Route index element={<PressReleaseCategoryPage />} />
                    <Route path=':slug' element={<PressReleaseDetailsPage />} />
                </Route>
            </Route>
            <Route path='/events'>
                <Route index element={<EventPage />} />
                <Route path=':category/*'>
                    <Route index element={<EventCategoryPage />} />
                    <Route path=':slug' element={<EventDetailsPage />} />
                </Route>
            </Route>
            <Route path='*' element={<PageNotFound />} />
        </Switch>
    );
};

export default NewsAndEventRoutes;
