import React from "react";
import EachPhoto from "./EachPhoto";
import { NavLink, useParams } from "react-router-dom";
import Pagination from "./Pagination";
import { ThreeDots } from "react-loader-spinner";

export default function PhotoGallery({
    tabs_category,
    photos,
    totalPages,
    state,
}) {
    return (
        <div>
            <div
                className='bg-1 bg-theme-'
                style={{ height: "112px", backgroundColor: "#12B76A" }}
            >
                <div className='container d-flex flex-column justify-content-center h-100'>
                    <h1 className='fw-400 text-white'>Image Gallery</h1>
                </div>
            </div>
            {state === "loading" ? (
                <div
                    style={{
                        minHeight: "100vh",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <ThreeDots
                        visible={true}
                        height='80'
                        width='80'
                        color='#4fa94d'
                        radius='9'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClass=''
                    />
                </div>
            ) : (
                <section className='gallery-area gallery-popup pt-100 pb-70'>
                    <div className='container'>
                        <Tabs
                            tabs_category={tabs_category}
                            link={"/media-room/photo-gallery"}
                        />
                        <div className='shorting'>
                            <div className='row'>
                                {photos?.map((photo) => (
                                    <EachPhoto
                                        key={photo?.id}
                                        img={photo?.data?.photo?.url}
                                        content={photo?.data?.content}
                                        alt={photo?.data?.photo?.alt}
                                    />
                                ))}
                            </div>
                        </div>
                        <br />
                        <br />
                        <Pagination total_pages={totalPages} />
                    </div>
                </section>
            )}
        </div>
    );
}

// Am sharing this component with the VideoGallery
export const Tabs = ({ tabs_category, link }) => {
    const { category } = useParams();
    const categoryPath = category || "all";

    return (
        <div
            className='row'
            style={{ justifyContent: "center" }}
            bis_skin_checked='1'
        >
            <div className=' ' bis_skin_checked='1'>
                <div
                    className='shorting-menu d-flex image_scroll_bar'
                    style={{
                        overflowX: "auto",
                    }}
                    bis_skin_checked='1'
                >
                    <NavLink
                        style={{
                            background:
                                categoryPath === "all"
                                    ? "#00aa55"
                                    : "transparent",
                            color: categoryPath === "all" ? "white" : "black",
                        }}
                        to={`${link}`}
                        className={`filter`}
                        // data-filter='all'
                        // fdprocessedid='03j22'
                    >
                        All
                    </NavLink>
                    {tabs_category?.map((category) => (
                        <NavLink
                            key={category?.id}
                            style={{
                                whiteSpace: "nowrap",
                            }}
                            to={`${link}/${category?.uid}`}
                            className={`filter  ${
                                categoryPath === category?.uid && "active"
                            }`}
                            data-filter='.business'
                            fdprocessedid='r0rmbc'
                        >
                            {category?.data?.title[0]?.text}
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    );
};
